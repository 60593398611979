import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { IoSearch } from 'react-icons/io5';
import AddContactForm from './AddContactForm';
import CountryFlag from '../FlagComponent/CountryFlag';
import LastMessageUtils from './LastMessageUtils'
import { renderMessageContent } from './LastMessageUtils';  // Import the updated function

import '../../Styles/ChatsList.css';
import '../../Styles/Header.css';
import { Link } from 'react-router-dom';
import { FaCheck } from "react-icons/fa6";
import { BiCheckDouble } from "react-icons/bi";
import { API_URL } from '../../config';

const ChatsList = ({isActive}) => {
  const [chats, setChats] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [listeSize, setListeSize] = useState(10);
  const [filterOption, setFilterOption] = useState('all');
  const [isActiveState, setIsActiveState] = useState(isActive);
  const ref = useRef();
  useEffect(() => {
    const fetchChats = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}getWhatsappNames`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });        
        const uniqueChatsMap = new Map();
        response.data.data.forEach(chat => {
          if (!uniqueChatsMap.has(chat.phone)) {
            uniqueChatsMap.set(chat.phone, chat);
          } else {
            const existingChat = uniqueChatsMap.get(chat.phone);
            if (chat.timestamp > existingChat.timestamp) {
              uniqueChatsMap.set(chat.phone, chat);
            }
          }
        });
  
        const uniqueChats = Array.from(uniqueChatsMap.values());
        setChats(uniqueChats);
      } catch (error) {
        console.error('Error fetching chats:', error);
      }
    };
  
    fetchChats();
    const intervalId = setInterval(fetchChats, 2000);
    return () => clearInterval(intervalId);
  }, []);
  

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}getAdmins`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.data.status === 'success') {
          const unblockedAdmins = response.data.data.filter(admin => !admin.blocked);
          setAdmins(unblockedAdmins);
        }
      } catch (error) {
        console.error('Error fetching admins:', error);
      }
    };

    fetchAdmins();
  }, []);

  const sortedChats = useMemo(() => chats.sort((a, b) => b.timestamp - a.timestamp), [chats]);
  
  const normalizePhoneNumber = (phone) => {
    if (!phone) return phone;
    return phone.replace(/^(\+0|\+|0)/, '');
  };

  const searchedChats = useMemo(() => {
    const searchTermTrimmed = searchTerm.trim().toLowerCase();
    if (!searchTermTrimmed) return sortedChats;
    return sortedChats.filter(chat =>
      (chat.nickname && chat.nickname.toLowerCase().includes(searchTermTrimmed)) ||
      (chat.name && chat.name.toLowerCase().includes(searchTermTrimmed)) ||
      (chat.phone && chat.phone.toLowerCase().includes(normalizePhoneNumber(searchTermTrimmed))) ||
      (chat.previousName && chat.previousName.toLowerCase().includes(searchTermTrimmed))
    );
  }, [searchTerm, sortedChats]);

  const filteredChats = useMemo(() => {
    if (filterOption === 'all') return searchedChats;
    return searchedChats.filter(chat => chat.adminName === filterOption);
  }, [filterOption, searchedChats]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(()=> {
    if(parseInt(searchTerm[0]) || searchTerm[0] === "+") {
      ref.current.dir = 'ltr'
    }
    else {
      ref.current.dir = 'rtl'
    }

  },[searchTerm])

  const handleFilterChange = event => {
    setFilterOption(event.target.value);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const now = new Date();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    if (now.toDateString() === date.toDateString()) {
      return `${hours}:${minutes}`;
    } else {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      return `${hours}:${minutes}, ${day}/${month}`;
    }
  };

  const listPage = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const bottomScroll = scrollHeight - clientHeight;
    if(scrollTop > (bottomScroll - 10) && listeSize < filteredChats.length) {
        setListeSize(listeSize + 10);
    }
  };

  return (
    <div className={`chats-container ${!isActiveState ? 'hide-mobile' : ''}`}>
      <div className="chats-header-container">
        <div className="title">المحادثات</div>
        <AddContactForm />
      </div>

      <div className="chats-search-container">
        <input
          type="text"
          placeholder="بحث"
          ref={ref}
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <IoSearch className="search-icon" />
      </div>

      <div className="chats-filter-container">
        <p>فلتر حسب المسؤول</p>
        <select className="filter-option" value={filterOption} onChange={handleFilterChange}>
          <option value="all">الكل</option>
          {admins.map((admin, index) => (
            <option key={index} value={admin.name}>{admin.name}</option>
          ))}
        </select>
      </div>

      <div className="chats-list-container">
        {filteredChats.length === 0 ? (
          <div className="no-results-message">لا يوجد نتائج</div>
        ) : (
          <div className="chats" onScroll={listPage}>
            {filteredChats.slice(0,listeSize).map((chat, index) => (
              <Link key={index} to={`/chat/${chat.phone}`} className="chat-link" onClick={()=>{setIsActiveState(false)}} >
                <div className="chat-item">
                  <div className="flag" key={chat.id}>
                    <CountryFlag phoneNumber={`+${chat.phone}`} />
                  </div>
                  <div className="chat-info">
                  <div className="chat-list-title">{chat.nickname || chat.realname || chat.previousName || chat.phone} ({chat.adminName})</div>
                    <div className="last-message">
                    {chat.sent
                      ? chat.status === 'sent'
                        ? <FaCheck className='sent-status' />
                        : chat.status === 'delivered'
                          ? <BiCheckDouble className='delivered-status' />
                          : chat.status === 'read'
                            ? <BiCheckDouble className='read-status' />
                            : ''
                      : ''} &nbsp;
                      {renderMessageContent(chat.message)}
                    </div>
                  </div>

                  <div className="last-message-info">
                    <div className="last-message-date">{formatDate(chat.timestamp)}</div>
                    <div>{chat.unreadMessages}</div><div></div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatsList;
